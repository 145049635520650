import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { MDBRow, MDBCol } from 'mdbreact';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faClock, faHome } from '@fortawesome/free-solid-svg-icons'
import 'leaflet/dist/leaflet.css';
import Leaflet from 'leaflet';

const greenIcon = new Leaflet.Icon({
  iconUrl: require('../assets/css/marker/marker-green.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  iconAnchor: [12, 41],
  popupAnchor: [0, -39]
});

class Contact extends Component {

  render() {
    return (
      <MDBRow className="Contact">
        <MDBRow className="Informations">
          <h1>Coordonnées</h1>
          <MDBRow>
            <MDBCol xs="6" md="6">
              <div className="Infos">
                <FontAwesomeIcon icon={faPhone} />
                &nbsp;
                06.13.46.37.24 (Jean)
                <br />
                06.61.22.23.20 (Marion)
              </div>
              <div className="Infos">
                <FontAwesomeIcon icon={faEnvelope} />
                &nbsp;
                marion@ajtservices.fr
              </div>
            </MDBCol>
            <MDBCol xs="6" md="6">
              <div className="Infos">
                <FontAwesomeIcon icon={faHome} />
                &nbsp;
                208 Route de Toulouse
                <br />
                33130 Bègles
              </div>
            </MDBCol>
            <MDBCol xs="12" md="12">
              <div className="Infos mt-2">
                <FontAwesomeIcon icon={faClock} />
                &nbsp;
                Ouvert du lundi au samedi, 8h-20h
              </div>
            </MDBCol>
          </MDBRow>
          <MDBRow className="Map">
            <Map center={[44.805420, -0.572380]} zoom={13} zoomControl={false} scrollWheelZoom={true}>
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker icon={greenIcon} position={[44.805420, -0.572380]}>
                <Popup>
                  208 Route de Toulouse <br /> 33130 Bègles
                </Popup>
              </Marker>
            </Map>
          </MDBRow>
        </MDBRow>      
      </MDBRow>
    )
  }
}

export default withRouter(Contact);