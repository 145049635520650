import React, { Component } from 'react';
import {
  MDBRow,
  MDBContainer,
  MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBCol,
  MDBView,
} from 'mdbreact';

const foussier = require('../assets/images/svg/foussier.png');
const wurth = require('../assets/images/svg/wurth.svg');
const glass = require('../assets/images/svg/glassolution.jpg');
const isea = require('../assets/images/svg/isea.png');
const mymotors = require('../assets/images/svg/mymotors.png');
const somfy = require('../assets/images/svg/somfy.png');
const ouveo = require('../assets/images/svg/ouveo.jpg');
const bricard = require('../assets/images/svg/bricard.png');
const fichet = require('../assets/images/svg/fichet.png');

const abn = require('../assets/images/svg/abn_standing.jpg');
const ajp = require('../assets/images/svg/ajp.png');
const aldi = require('../assets/images/svg/aldi.svg');
const arlac = require('../assets/images/svg/arlac.png');
const armandthiery = require('../assets/images/svg/armandthiery.svg');
const auchan = require('../assets/images/svg/auchan.svg');
const babilou = require('../assets/images/svg/babilou.svg');
const emalec = require('../assets/images/svg/emalec.png');
const frankprovost = require('../assets/images/svg/franckprovost.png');
const gifi = require('../assets/images/svg/gifi.png');
const ideal_devis = require('../assets/images/svg/ideal_devis.png');
const maxi_toys = require('../assets/images/svg/maxi-toys.svg');
const nafnaf = require('../assets/images/svg/nafnaf.png');
const paul = require('../assets/images/svg/paul.jpg');
const phinelec = require('../assets/images/svg/phinelec.jpg');
const picard = require('../assets/images/svg/picard.svg');
const presta_service = require('../assets/images/svg/presta_service.png');
const roxy = require('../assets/images/svg/roxy.svg');
const sephora = require('../assets/images/svg/sephora.png');
const snipe = require('../assets/images/svg/snipe.svg');
const superdry = require('../assets/images/svg/superdry.svg');
const zadigvoltaire = require('../assets/images/svg/zadig&voltaire.svg');

const marion_jean = require('../assets/images/marion-jean.jpg');
const stagiaire = require('../assets/images/stagiaire.jpg');

class Societe extends Component {
  render() {
    if (window.innerWidth > 1024) {
      return (
        <MDBRow className="Societe">
          <h1>Présentation</h1>
          <MDBRow>
            <MDBCol xs="6" md="6" className="Description">
              <span className="Bold">AJT Services</span> est une petite entreprise qui a vu le jour en 2019.
              Nous intervenons dans la réparation et la pose de serrures et de vitres.
            </MDBCol>
            <MDBCol xs="6" md="6" className="Groupe">
              <img src={marion_jean} className="GroupeImg" alt="marion_jean" />
            </MDBCol>
            <MDBCol xs="6" md="6" className="Groupe">
              <img src={stagiaire} className="GroupeImg" alt="stagiaire" />
            </MDBCol>
            <MDBCol xs="6" md="6" className="Description">
              Notre mobilité nous permet d'intervenir dans un large périmètre et notre polyvalence nous permet de répondre à toutes vos demandes.
            </MDBCol>

            <MDBCol xs="12" md="12">
              <h3 className="Proud">
                Nos partenaires
              </h3>
            </MDBCol>

            <MDBCol xs="12" md="12">
              <MDBContainer className="Carousel">
                <MDBCarousel
                  activeItem={1}
                  length={3}
                  showControls={false}
                  showIndicators={false}
                  className="z-depth-1"
                  interval={2500}
                >
                  <MDBCarouselInner>
                    <MDBCarouselItem itemId="1">
                      <MDBRow className="Company">
                        <MDBCol xs="3" md="3">
                        <MDBView>
                            <img
                              className="d-block w-100"
                              src={foussier}
                              alt="One"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                        <MDBView>
                            <img
                              className="d-block w-100"
                              src={wurth}
                              alt="Two"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                        <MDBView>
                            <img
                              className="d-block w-100"
                              src={glass}
                              alt="Three"
                            />
                          </MDBView>
                        </MDBCol>
                      </MDBRow>
                    </MDBCarouselItem>

                    <MDBCarouselItem itemId="2">
                      <MDBRow className="Company">
                        <MDBCol xs="3" md="3">
                        <MDBView>
                            <img
                              className="d-block w-100"
                              src={isea}
                              alt="Four"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                        <MDBView>
                            <img
                              className="d-block w-100"
                              src={mymotors}
                              alt="Five"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                        <MDBView>
                            <img
                              className="d-block w-100"
                              src={somfy}
                              alt="Six"
                            />
                          </MDBView>
                        </MDBCol>
                      </MDBRow>
                    </MDBCarouselItem>

                    <MDBCarouselItem itemId="3">
                      <MDBRow className="Company">
                        <MDBCol xs="3" md="3">
                        <MDBView>
                            <img
                              className="d-block w-100"
                              src={ouveo}
                              alt="Four"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                        <MDBView>
                            <img
                              className="d-block w-100"
                              src={bricard}
                              alt="Five"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                        <MDBView>
                            <img
                              className="d-block w-100"
                              src={fichet}
                              alt="Six"
                            />
                          </MDBView>
                        </MDBCol>
                      </MDBRow>
                    </MDBCarouselItem>
                  </MDBCarouselInner>
                </MDBCarousel>
              </MDBContainer>
            </MDBCol>

            <MDBCol xs="12" md="12">
              <h3 className="Proud">
                Ils font partie de nos clients
              </h3>
            </MDBCol>

            <MDBCol xs="12" md="12">
              <MDBContainer className="Carousel">
                <MDBCarousel
                  activeItem={1}
                  length={8}
                  showControls={false}
                  showIndicators={false}
                  className="z-depth-1"
                  interval={2000}
                >
                  <MDBCarouselInner>

                  <MDBCarouselItem itemId="1">
                      <MDBRow className="Company">
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={maxi_toys}
                              alt="Nine"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={aldi}
                              alt="Two"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={armandthiery}
                              alt="Four"
                            />
                          </MDBView>
                        </MDBCol>
                      </MDBRow>
                    </MDBCarouselItem>

                    <MDBCarouselItem itemId="2">
                      <MDBRow className="Company">
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={auchan}
                              alt="Six"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={babilou}
                              alt="Six"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={frankprovost}
                              alt="Seven"
                            />
                          </MDBView>
                        </MDBCol>
                      </MDBRow>
                    </MDBCarouselItem>

                    <MDBCarouselItem itemId="3">
                      <MDBRow className="Company">
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={presta_service}
                              alt="Twenty"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={nafnaf}
                              alt="Twentyone"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={emalec}
                              alt="Twentytwo"
                            />
                          </MDBView>
                        </MDBCol>
                      </MDBRow>
                    </MDBCarouselItem>

                    <MDBCarouselItem itemId="4">
                      <MDBRow className="Company">
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={picard}
                              alt="Eleven"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={roxy}
                              alt="Twelve"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={sephora}
                              alt="Thirteen"
                            />
                          </MDBView>
                        </MDBCol>
                      </MDBRow>
                    </MDBCarouselItem>

                    <MDBCarouselItem itemId="5">
                      <MDBRow className="Company">
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={snipe}
                              alt="Fourteen"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={superdry}
                              alt="Fifteen"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={zadigvoltaire}
                              alt="Sixteen"
                            />
                          </MDBView>
                        </MDBCol>
                      </MDBRow>
                    </MDBCarouselItem>

                    <MDBCarouselItem itemId="6">
                      <MDBRow className="Company">
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={ideal_devis}
                              alt="Seventeen"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={abn}
                              alt="Eighteen"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={phinelec}
                              alt="Nineteen"
                            />
                          </MDBView>
                        </MDBCol>
                      </MDBRow>
                    </MDBCarouselItem>

                    <MDBCarouselItem itemId="7">
                      <MDBRow className="Company">
                        <MDBCol xs="3" md="3">
                        <MDBView>
                            <img
                              className="d-block w-100"
                              src={ajp}
                              alt="One"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="2" md="2"></MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block"
                              src={arlac}
                              height="200px"
                              alt="Three"
                            />
                          </MDBView>
                        </MDBCol>
                      </MDBRow>
                    </MDBCarouselItem>

                    <MDBCarouselItem itemId="8">
                      <MDBRow className="Company">
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={gifi}
                              alt="Eight"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="2" md="2"></MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={paul}
                              alt="Ten"
                            />
                          </MDBView>
                        </MDBCol>
                      </MDBRow>
                    </MDBCarouselItem>
                  </MDBCarouselInner>
                </MDBCarousel>
              </MDBContainer>
            </MDBCol>
          </MDBRow>
        </MDBRow>
      )
    } else {
      return (
        <MDBRow className="Societe">
          <h1>Présentation</h1>
          <MDBRow>
            <MDBCol xs="12" md="12" className="Description">
              <span className="Bold">AJT Services</span> est une petite entreprise qui a vu le jour en 2019.
              Nous intervenons dans la réparation et la pose de serrures et de vitres.
            </MDBCol>
            <MDBCol xs="12" md="12" className="Groupe">
              <img src={marion_jean} className="GroupeImg" alt="marion_jean" />
            </MDBCol>
            <MDBCol xs="12" md="12" className="Description">
              Notre mobilité nous permet d'intervenir dans un large périmètre et notre polyvalence nous permet de répondre à toutes vos demandes.
            </MDBCol>
            <MDBCol xs="12" md="12" className="Groupe">
              <img src={stagiaire} className="GroupeImg" alt="stagiaire" />
            </MDBCol>

            {/* <MDBCol xs="12" md="12">
              <h3 className="Proud">
                Nos partenaires
              </h3>
            </MDBCol> */}

            {/* <MDBCol xs="12" md="12">
              <MDBContainer className="Carousel">
                <MDBCarousel
                  activeItem={1}
                  length={3}
                  showControls={false}
                  showIndicators={false}
                  className="z-depth-1"
                  interval={2500}
                >
                  <MDBCarouselInner>
                    <MDBCarouselItem itemId="1">
                      <MDBRow className="Company">
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={foussier}
                              alt="One"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={wurth}
                              alt="Two"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={glass}
                              alt="Three"
                            />
                          </MDBView>
                        </MDBCol>
                      </MDBRow>
                    </MDBCarouselItem>

                    <MDBCarouselItem itemId="2">
                      <MDBRow className="Company">
                        <MDBCol xs="3" md="3">
                        <MDBView>
                            <img
                              className="d-block w-100"
                              src={isea}
                              alt="Four"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                        <MDBView>
                            <img
                              className="d-block w-100"
                              src={mymotors}
                              alt="Five"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                        <MDBView>
                            <img
                              className="d-block w-100"
                              src={somfy}
                              alt="Six"
                            />
                          </MDBView>
                        </MDBCol>
                      </MDBRow>
                    </MDBCarouselItem>

                    <MDBCarouselItem itemId="3">
                      <MDBRow className="Company">
                        <MDBCol xs="3" md="3">
                        <MDBView>
                            <img
                              className="d-block w-100"
                              src={ouveo}
                              alt="Four"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                        <MDBView>
                            <img
                              className="d-block w-100"
                              src={bricard}
                              alt="Five"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                        <MDBView>
                            <img
                              className="d-block w-100"
                              src={fichet}
                              alt="Six"
                            />
                          </MDBView>
                        </MDBCol>
                      </MDBRow>
                    </MDBCarouselItem>
                  </MDBCarouselInner>
                </MDBCarousel>
              </MDBContainer>
            </MDBCol>

            <MDBCol xs="12" md="12">
              <h3 className="Proud">
                Ils font partie de nos clients
              </h3>
            </MDBCol>

            <MDBCol xs="12" md="12">
              <MDBContainer className="Carousel">
                <MDBCarousel
                  activeItem={1}
                  length={8}
                  showControls={false}
                  showIndicators={false}
                  className="z-depth-1"
                  interval={2000}
                >
                  <MDBCarouselInner>

                  <MDBCarouselItem itemId="1">
                      <MDBRow className="Company">
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={maxi_toys}
                              alt="Nine"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={aldi}
                              alt="Two"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={armandthiery}
                              alt="Four"
                            />
                          </MDBView>
                        </MDBCol>
                      </MDBRow>
                    </MDBCarouselItem>

                    <MDBCarouselItem itemId="2">
                      <MDBRow className="Company">
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={auchan}
                              alt="Six"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={babilou}
                              alt="Six"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={frankprovost}
                              alt="Seven"
                            />
                          </MDBView>
                        </MDBCol>
                      </MDBRow>
                    </MDBCarouselItem>

                    <MDBCarouselItem itemId="3">
                      <MDBRow className="Company">
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={presta_service}
                              alt="Twenty"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={nafnaf}
                              alt="Twentyone"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={emalec}
                              alt="Twentytwo"
                            />
                          </MDBView>
                        </MDBCol>
                      </MDBRow>
                    </MDBCarouselItem>

                    <MDBCarouselItem itemId="4">
                      <MDBRow className="Company">
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={picard}
                              alt="Eleven"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={roxy}
                              alt="Twelve"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={sephora}
                              alt="Thirteen"
                            />
                          </MDBView>
                        </MDBCol>
                      </MDBRow>
                    </MDBCarouselItem>

                    <MDBCarouselItem itemId="5">
                      <MDBRow className="Company">
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={snipe}
                              alt="Fourteen"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={superdry}
                              alt="Fifteen"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={zadigvoltaire}
                              alt="Sixteen"
                            />
                          </MDBView>
                        </MDBCol>
                      </MDBRow>
                    </MDBCarouselItem>

                    <MDBCarouselItem itemId="6">
                      <MDBRow className="Company">
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={ideal_devis}
                              alt="Seventeen"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={abn}
                              alt="Eighteen"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={phinelec}
                              alt="Nineteen"
                            />
                          </MDBView>
                        </MDBCol>
                      </MDBRow>
                    </MDBCarouselItem>

                    <MDBCarouselItem itemId="7">
                      <MDBRow className="Company">
                        <MDBCol xs="3" md="3">
                        <MDBView>
                            <img
                              className="d-block w-100"
                              src={ajp}
                              alt="One"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="2" md="2"></MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block"
                              src={arlac}
                              height="200px"
                              alt="Three"
                            />
                          </MDBView>
                        </MDBCol>
                      </MDBRow>
                    </MDBCarouselItem>

                    <MDBCarouselItem itemId="8">
                      <MDBRow className="Company">
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={gifi}
                              alt="Eight"
                            />
                          </MDBView>
                        </MDBCol>
                        <MDBCol xs="2" md="2"></MDBCol>
                        <MDBCol xs="3" md="3">
                          <MDBView>
                            <img
                              className="d-block w-100"
                              src={paul}
                              alt="Ten"
                            />
                          </MDBView>
                        </MDBCol>
                      </MDBRow>
                    </MDBCarouselItem>
                  </MDBCarouselInner>
                </MDBCarousel>
              </MDBContainer>
            </MDBCol> */}
          </MDBRow>
        </MDBRow>
      )
    }
  }
}

export default Societe;