import React, { Component } from 'react';

import { MDBRow } from 'mdbreact';
import ImageGallery from 'react-image-gallery';

import '../../../node_modules/react-image-gallery/styles/css/image-gallery.css';

class Real2 extends Component {

  render() {

    const images = [
      {
        original: require("../../assets/images/orencash/orencash1.jpg"),
        thumbnail: require("../../assets/images/orencash/orencash1.jpg")
      },
      {
        original: require("../../assets/images/orencash/orencash2.jpg"),
        thumbnail: require("../../assets/images/orencash/orencash2.jpg")
      },
      {
        original: require("../../assets/images/orencash/orencash3.jpg"),
        thumbnail: require("../../assets/images/orencash/orencash3.jpg")
      },
      {
        original: require("../../assets/images/orencash/orencash4.jpg"),
        thumbnail: require("../../assets/images/orencash/orencash4.jpg")
      }
    ]

    return (
      <MDBRow className="Real">
        <h1>Remplacement d'une porte d'entrée</h1>
        <ImageGallery
          items={images}
          showIndex={true}
          showPlayButton={false}
          showThumbnails={false}
          showFullscreenButton={false}
        />
      </MDBRow>
    );
  }

}

export default Real2;