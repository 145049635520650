import React, { Component } from 'react';
import { MDBRow } from 'mdbreact';
import { NavLink } from 'react-router-dom';

const logo = require('../assets/images/logo.jpg');

class Brand extends Component {
  render() {
    if (window.innerWidth > 1024) {
      return (
        <MDBRow className="Brand">
          <NavLink to="/accueil">
            <img className="Logo" src={logo} alt="logo" />
          </NavLink>
        </MDBRow>
      )
    } else {
      return null;
    }
  }
}

export default Brand;