import React from 'react';
import './App.css';
import { Switch, Route, Redirect } from 'react-router-dom';

import Accueil from './pages/Accueil';
import Tarifs from './pages/Tarifs';
import Real1 from './pages/Realisations/Real1';
import Real2 from './pages/Realisations/Real2';
import Real3 from './pages/Realisations/Real3';
import Real4 from './pages/Realisations/Real4';
import Real5 from './pages/Realisations/Real5';
import Real6 from './pages/Realisations/Real6';
import Real7 from './pages/Realisations/Real7';
import Real8 from './pages/Realisations/Real8';
import Real9 from './pages/Realisations/Real9';
import Real10 from './pages/Realisations/Real10';
import Real11 from './pages/Realisations/Real11';
import Real12 from './pages/Realisations/Real12';
import Real13 from './pages/Realisations/Real13';
import Real14 from './pages/Realisations/Real14';
import Realisations from './pages/Realisations';
import Societe from './pages/Societe';
import Contact from './pages/Contact';

//import Brand from './pages/Brand';

function App() {
  return (
    <Switch>
      <Route exact path="/"><Redirect to="/accueil" /></Route>
      <Route exact path="/accueil" component={Accueil} />
      <Route exact path="/tarifs" component={Tarifs} />
      <Route exact path="/realisations/armand-thiery" component={Real1} />
      <Route exact path="/realisations/or-en-cash" component={Real2} />
      <Route exact path="/realisations/aldi" component={Real3} />
      <Route exact path="/realisations/roxy" component={Real4} />
      <Route exact path="/realisations/donbosco" component={Real5} />
      <Route exact path="/realisations/z&v" component={Real6} />
      <Route exact path="/realisations/leclerc" component={Real7} />
      <Route exact path="/realisations/superdry" component={Real8} />
      <Route exact path="/realisations/aldi2" component={Real9} />
      <Route exact path="/realisations/hayon" component={Real10} />
      <Route exact path="/realisations/picard" component={Real11} />
      <Route exact path="/realisations/porte" component={Real12} />
      <Route exact path="/realisations/gifi" component={Real13} />
      <Route exact path="/realisations/auchan" component={Real14} />
      <Route exact path="/realisations" component={Realisations} />
      <Route exact path="/societe" component={Societe} />
      <Route exact path="/contact" component={Contact} />
    </Switch>
  );
}

export default App;
