import React, { Component } from 'react';
import { Nav, Navbar, NavItem, } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { NavLink } from 'react-router-dom';
import { 
  MDBContainer, 
  MDBHamburgerToggler, 
  MDBNavbarNav,
  MDBCollapse,
  MDBNavItem,

} from 'mdbreact';

class NavbarComponent extends Component {
  state = {
    collapse1: false,
    collapseID: ''
  }
  
  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({ 
      collapseID: (prevState.collapseID !== collapseID ? collapseID : ''),
      collapse1: !this.state.collapse1
    }));
  }
  
  toggleSingleCollapse = collapseId => {
    console.log('ici');
    this.setState({
      ...this.state,
      [collapseId]: !this.state[collapseId],
    });
  }

  close = () => {
    this.setState({ collapse1: false });
  }

  render () {
    if (window.innerWidth > 1024) {
      return (
            <Navbar className="Navbar navbar navbar-sticky" dark expand="md">
              <Nav className="mr-auto" navbar>
                <NavItem>
                  <NavLink className="nav-link" to="/accueil">
                    Accueil
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="nav-link" to="/tarifs">
                    Tarifs
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="nav-link" to="/realisations">
                    Réalisations
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="nav-link" to="/societe">
                    Société
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="nav-link" to="/contact">
                    Contact
                  </NavLink>
                </NavItem>
              </Nav>
              {/*<FaFacebookF />
              <FaInstagram />*/}
              <a href={`https://www.facebook.com/AJT-services-112814636831593`}>
                <FontAwesomeIcon icon={faFacebookF} size="2x" />
              </a>
              &nbsp;
              <a href={`https://www.instagram.com/ajt_services/?hl=af`}>
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
            </Navbar>
      );
    } else {
      return (
        <MDBContainer className="Navbar">
          <MDBNavbarNav style={{ marginBottom: '10px', marginTop: '20px' }}>
            <MDBContainer>
              <MDBHamburgerToggler color="#019446" id="hamburger1" onClick={() => this.toggleSingleCollapse('collapse1')} />
              <MDBCollapse isOpen={this.state.collapse1} navbar>
                <MDBNavbarNav left>
                  <MDBNavItem active>
                    <NavLink className="nav-link" to="/accueil" onClick={this.close}>
                      Accueil  
                    </NavLink>  
                  </MDBNavItem> 
                  <MDBNavItem>
                    <NavLink className="nav-link" to="/tarifs" onClick={this.close}>
                      Tarifs
                    </NavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <NavLink className="nav-link" to="/realisations" onClick={this.close}>
                      Réalisations
                    </NavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <NavLink className="nav-link" to="/societe" onClick={this.close}>
                      Société
                    </NavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <NavLink className="nav-link" to="/contact" onClick={this.close}>
                      Contact
                    </NavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <a href={`https://www.facebook.com/AJT-services-112814636831593`}>
                      <FontAwesomeIcon icon={faFacebookF} size="2x" />
                    </a>
                    &nbsp;
                    <a href={`https://www.instagram.com/ajt_services/?hl=af`}>
                      <FontAwesomeIcon icon={faInstagram} size="2x" />
                    </a>
                  </MDBNavItem>
                </MDBNavbarNav>
              </MDBCollapse>
            </MDBContainer>
          </MDBNavbarNav>
        </MDBContainer>
      )
    }
  }
}

export default NavbarComponent;
