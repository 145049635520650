import React, { Component } from 'react';

import { MDBRow } from 'mdbreact';
import ImageGallery from 'react-image-gallery';

import '../../../node_modules/react-image-gallery/styles/css/image-gallery.css';

class Real3 extends Component {

  render() {

    const images = [
      {
        original: require("../../assets/images/aldi/aldi1.jpg"),
        thumbnail: require("../../assets/images/aldi/aldi1.jpg")
      },
      {
        original: require("../../assets/images/aldi/aldi2.jpg"),
        thumbnail: require("../../assets/images/aldi/aldi2.jpg")
      },
      {
        original: require("../../assets/images/aldi/aldi3.jpg"),
        thumbnail: require("../../assets/images/aldi/aldi3.jpg")
      },
      {
        original: require("../../assets/images/aldi/aldi4.jpg"),
        thumbnail: require("../../assets/images/aldi/aldi4.jpg")
      }
    ];

    return (
      <MDBRow className="Real">
        <h1>Remplacement d'un axe de rideau métallique</h1>
        <ImageGallery
          items={images}
          showIndex={true}
          showPlayButton={false}
          showThumbnails={false}
          showFullscreenButton={false}
        />
      </MDBRow>
    );
  }
}

export default Real3;