import React, { Component } from 'react';

import { MDBRow } from 'mdbreact';
import ImageGallery from 'react-image-gallery';

import '../../../node_modules/react-image-gallery/styles/css/image-gallery.css';

class Real4 extends Component {

  render() {

    const images = [
      {
        original: require("../../assets/images/roxy/roxy1.jpg"),
        thumbnail: require("../../assets/images/roxy/roxy1.jpg")
      },
      {
        original: require("../../assets/images/roxy/roxy2.jpg"),
        thumbnail: require("../../assets/images/roxy/roxy2.jpg")
      },
      {
        original: require("../../assets/images/roxy/roxy3.jpg"),
        thumbnail: require("../../assets/images/roxy/roxy3.jpg")
      },
      {
        original: require("../../assets/images/roxy/roxy4.jpg"),
        thumbnail: require("../../assets/images/roxy/roxy4.jpg")
      }
    ];

    return (
      <MDBRow className="Real">
        <h1>Dépose et pose d'un axe de rideau métallique</h1>
        <ImageGallery
          items={images}
          showIndex={true}
          showPlayButton={false}
          showThumbnails={false}
          showFullscreenButton={false}
        />
      </MDBRow>
    );
  }
}

export default Real4;