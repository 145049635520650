import React, { Component } from 'react';

import { MDBRow } from 'mdbreact';
import ImageGallery from 'react-image-gallery';

import '../../../node_modules/react-image-gallery/styles/css/image-gallery.css';

class Real5 extends Component {

  render() {

    const images = [
      {
        original: require("../../assets/images/donbosco/donbosco1.jpg"),
        thumbnail: require("../../assets/images/donbosco/donbosco1.jpg")
      },
      {
        original: require("../../assets/images/donbosco/donbosco2.jpg"),
        thumbnail: require("../../assets/images/donbosco/donbosco2.jpg")
      },
      {
        original: require("../../assets/images/donbosco/donbosco3.jpg"),
        thumbnail: require("../../assets/images/donbosco/donbosco3.jpg")
      },
      {
        original: require("../../assets/images/donbosco/donbosco4.jpg"),
        thumbnail: require("../../assets/images/donbosco/donbosco4.jpg")
      },
      {
        original: require("../../assets/images/donbosco/donbosco5.jpg"),
        thumbnail: require("../../assets/images/donbosco/donbosco5.jpg")
      },
      {
        original: require("../../assets/images/donbosco/donbosco6.jpg"),
        thumbnail: require("../../assets/images/donbosco/donbosco6.jpg")
      },
      {
        original: require("../../assets/images/donbosco/donbosco7.jpg"),
        thumbnail: require("../../assets/images/donbosco/donbosco7.jpg")
      },
      {
        original: require("../../assets/images/donbosco/donbosco8.jpg"),
        thumbnail: require("../../assets/images/donbosco/donbosco8.jpg")
      },
      {
        original: require("../../assets/images/donbosco/donbosco9.jpg"),
        thumbnail: require("../../assets/images/donbosco/donbosco9.jpg")
      },
      {
        original: require("../../assets/images/donbosco/donbosco10.jpg"),
        thumbnail: require("../../assets/images/donbosco/donbosco10.jpg")
      },
      {
        original: require("../../assets/images/donbosco/donbosco11.jpg"),
        thumbnail: require("../../assets/images/donbosco/donbosco11.jpg")
      },
      {
        original: require("../../assets/images/donbosco/donbosco12.jpg"),
        thumbnail: require("../../assets/images/donbosco/donbosco12.jpg")
      },
      {
        original: require("../../assets/images/donbosco/donbosco13.jpg"),
        thumbnail: require("../../assets/images/donbosco/donbosco13.jpg")
      },
      {
        original: require("../../assets/images/donbosco/donbosco14.jpg"),
        thumbnail: require("../../assets/images/donbosco/donbosco14.jpg")
      },
      {
        original: require("../../assets/images/donbosco/donbosco15.jpg"),
        thumbnail: require("../../assets/images/donbosco/donbosco15.jpg")
      },
      {
        original: require("../../assets/images/donbosco/donbosco16.jpg"),
        thumbnail: require("../../assets/images/donbosco/donbosco16.jpg")
      },
      {
        original: require("../../assets/images/donbosco/donbosco17.jpg"),
        thumbnail: require("../../assets/images/donbosco/donbosco17.jpg")
      },
      {
        original: require("../../assets/images/donbosco/donbosco18.jpg"),
        thumbnail: require("../../assets/images/donbosco/donbosco18.jpg")
      },
      {
        original: require("../../assets/images/donbosco/donbosco19.jpg"),
        thumbnail: require("../../assets/images/donbosco/donbosco19.jpg")
      },
      {
        original: require("../../assets/images/donbosco/donbosco20.jpg"),
        thumbnail: require("../../assets/images/donbosco/donbosco20.jpg")
      },
      {
        original: require("../../assets/images/donbosco/donbosco21.jpg"),
        thumbnail: require("../../assets/images/donbosco/donbosco21.jpg")
      },
      {
        original: require("../../assets/images/donbosco/donbosco22.jpg"),
        thumbnail: require("../../assets/images/donbosco/donbosco22.jpg")
      },
      {
        original: require("../../assets/images/donbosco/donbosco23.jpg"),
        thumbnail: require("../../assets/images/donbosco/donbosco23.jpg")
      }
    ];
    
    return (
      <MDBRow className="Real">
        <h1>Mise aux normes de plusieurs ouvertures</h1>
        <ImageGallery
          items={images}
          showIndex={true}
          showPlayButton={false}
          showThumbnails={false}
          showFullscreenButton={false}
        />
      </MDBRow>
    )
  }
}

export default Real5;