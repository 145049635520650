import { MDBRow } from 'mdbreact';
import React, { Component } from 'react';

import ImageGallery from 'react-image-gallery';

import '../../../node_modules/react-image-gallery/styles/css/image-gallery.css';

class Real1 extends Component {

  render() {

    const images = [
      {
        original: require("../../assets/images/armandthiery/armandthiery1.jpg"),
        thumbnail: require("../../assets/images/armandthiery/armandthiery1.jpg")
      },
      {
        original: require("../../assets/images/armandthiery/armandthiery2.jpg"),
        thumbnail: require("../../assets/images/armandthiery/armandthiery2.jpg")
      },
      {
        original: require("../../assets/images/armandthiery/armandthiery3.jpg"),
        thumbnail: require("../../assets/images/armandthiery/armandthiery3.jpg")
      }
    ]

    return (
      <MDBRow className="Real">
        <h1>Changement d'un rideau métallique</h1>
        <ImageGallery 
          items={images}
          showIndex={true}
          showPlayButton={false}
          showThumbnails={false}
          showFullscreenButton={false}
        />
      </MDBRow>
    );
  }

}

export default Real1;