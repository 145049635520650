import React, { Component } from 'react';

import { MDBRow, MDBTable, MDBTableHead, MDBTableBody, MDBCol } from 'mdbreact';

class Tarifs extends Component {
  // constructor(props) {
    // super(props);
    // this.state = {
    //   firstName: "",
    //   lastName: "",
    //   phone: "",
    //   email: "",
    //   object: "",
    //   message: ""
    // }

    // this.handleChange = this.handleChange.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
  // }

  // handleChange(e) {
  //   const { target } = e;
  //   const { name, value } = target;

  //   this.setState({ [name]: value });
  // }

  // handleSubmit(e) {
    // const { firstName, lastName, phone, email, object, message } = this.state;
    // console.log('firstName', firstName);
    // console.log('lastName', lastName);
    // console.log('phone', phone);
    // console.log('email', email);
    // console.log('object', object);
    // console.log('message', message);
  // }

  render() {
    // const { firstName, lastName, phone, email, object, message } = this.state;
    return (
      <MDBRow className="Tarifs">
        <h1>Tarifs</h1>
        <MDBRow>
          <MDBCol xs="6" md="6">
            <MDBRow className="DescriptionTarif">
              Nous proposons des tarifs avec les frais de déplacements qui peuvent varier en fonction du lieu de chantier.
            </MDBRow>
          </MDBCol>
          <MDBCol xs="6" md="6" className="d-flex justify-content-center">
            <MDBTable className="TableTarif">
              <MDBTableHead>
              </MDBTableHead>
              <MDBTableBody>
                <tr>
                  <td>CUB</td>
                  <td>35€</td>
                </tr>
                <tr>
                  <td>Hors CUB</td>
                  <td>50€</td>
                </tr>
                <tr>
                  <td>Hors département</td>
                  <td>Nous contacter</td>
                </tr>
              </MDBTableBody>
            </MDBTable>
          </MDBCol>
        </MDBRow>
        <MDBRow className="More">
          Nous pouvons réaliser un devis gratuitement !
          <br />
          Pour plus d'informations, vous pouvez nous contacter par téléphone au 06.61.22.23.20.
           {/* ou en remplissant le formulaire ci-dessous */}
        </MDBRow>
        {/* <MDBRow className="Form">
          <MDBCol xs="6" md="6">
            <div className="form-group">
              <input type="text" className="form-control form-control-lg" placeholder="Prénom" name="firstName" onChange={this.handleChange} />
            </div>
          </MDBCol>
          <MDBCol xs="6" md="6">
            <div className="form-group">
              <input type="text" className="form-control form-control-lg" placeholder="Nom" name="lastName" onChange={this.handleChange} />
            </div>
          </MDBCol>
          <MDBCol xs="6" md="6">
            <div className="form-group">
              <input type="email" className="form-control form-control-lg" placeholder="Adresse mail" name="email" onChange={this.handleChange} />
            </div>
          </MDBCol>
          <MDBCol xs="6" md="6">
            <div className="form-group">
              <input type="text" className="form-control form-control-lg" placeholder="Numéro de téléphone" name="phone" onChange={this.handleChange} />
            </div>
          </MDBCol>
          <MDBCol xs="12" md="12">
            <div className="form-group">
              <input type="text" className="form-control form-control-lg" placeholder="Objet" name="object" onChange={this.handleChange} />
            </div>
          </MDBCol>
          <MDBCol xs="12" md="12">
            <div className="form-group">
              <textarea className="form-control form-control-lg" placeholder="Message" name="message" onChange={this.handleChange} />
            </div>
          </MDBCol>
          <MDBRow className="Send">
            <button onClick={this.handleSubmit} type="button" className="btn Button btn-rounded">Envoyer</button>
          </MDBRow>
        </MDBRow> */}
      </MDBRow>
    )
  }
}

export default Tarifs;