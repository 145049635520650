import React, { Component } from 'react';
import { MDBRow } from 'mdbreact';
import { NavLink } from 'react-router-dom';

class Realisations extends Component {
  render() {
    return (
      <MDBRow className="Realisations">
        <h1>Réalisations</h1>
        <MDBRow className="RealList">
          <NavLink 
            to="/realisations/armand-thiery"
            className="nav_link"
          >
            <h4>Changement d'un rideau métallique</h4>
          </NavLink>
          <NavLink 
            to="/realisations/or-en-cash"
          >
            <h4>Remplacement d'une porte d'entrée</h4>
          </NavLink>
          <NavLink
            to="/realisations/aldi"
          >
            <h4>Remplacement d'un axe de rideau métallique</h4>
          </NavLink>
          <NavLink
            to="/realisations/roxy"
          >
            <h4>Dépose et pose d'un axe de rideau métallique</h4>
          </NavLink>
          <NavLink
            to="/realisations/donbosco"
          >
            <h4>Mise aux normes de plusieurs ouvertures</h4>
          </NavLink>
          <NavLink
            to="/realisations/z&v"
          >
            <h4>Pose d'un volet roulant</h4>
          </NavLink>
          <NavLink
            to="/realisations/leclerc"
          >
            <h4>Pose d'une porte blindée</h4>
          </NavLink>
          <NavLink
            to="/realisations/superdry"
          >
            <h4>Remplacement d'un verre garde corps</h4>
          </NavLink>
          <NavLink
            to="/realisations/aldi2"
          >
            <h4>Clôture pour la protection d'un groupe froid</h4>
          </NavLink>
          <NavLink
            to="/realisations/hayon"
          >
            <h4>Pose d'un hayon</h4>
          </NavLink>
          <NavLink
            to="/realisations/picard"
          >
            <h4>Changement d'un axe de rideau métallique</h4>
          </NavLink>
          <NavLink
            to="/realisations/porte"
          >
            <h4>Mise en sécurité d'une porte sectionnalle</h4>
          </NavLink>
          <NavLink
            to="/realisations/gifi"
          >
            <h4>Réparation du bardage</h4>
          </NavLink>
          <NavLink
            to="/realisations/auchan"
          >
            <h4>Pose d'un rideau métallique</h4>
          </NavLink>
        </MDBRow>
      </MDBRow>
    )
  }
}

export default Realisations;