import React, { Component } from 'react';

import { MDBRow } from 'mdbreact';
import ImageGallery from 'react-image-gallery';

import '../../../node_modules/react-image-gallery/styles/css/image-gallery.css';

class Real6 extends Component {

  render() {
    
    const images = [
      {
        original: require("../../assets/images/zadig&voltaire/z&v1.jpg"),
        thumbnail: require("../../assets/images/zadig&voltaire/z&v1.jpg")
      },
      {
        original: require("../../assets/images/zadig&voltaire/z&v2.jpg"),
        thumbnail: require("../../assets/images/zadig&voltaire/z&v2.jpg")
      },
      {
        original: require("../../assets/images/zadig&voltaire/z&v3.jpg"),
        thumbnail: require("../../assets/images/zadig&voltaire/z&v3.jpg")
      },
      {
        original: require("../../assets/images/zadig&voltaire/z&v4.jpg"),
        thumbnail: require("../../assets/images/zadig&voltaire/z&v4.jpg")
      },
      {
        original: require("../../assets/images/zadig&voltaire/z&v5.jpg"),
        thumbnail: require("../../assets/images/zadig&voltaire/z&v5.jpg")
      },
      {
        original: require("../../assets/images/zadig&voltaire/z&v6.jpg"),
        thumbnail: require("../../assets/images/zadig&voltaire/z&v6.jpg")
      },
      {
        original: require("../../assets/images/zadig&voltaire/z&v7.jpg"),
        thumbnail: require("../../assets/images/zadig&voltaire/z&v7.jpg")
      },
      {
        original: require("../../assets/images/zadig&voltaire/z&v8.jpg"),
        thumbnail: require("../../assets/images/zadig&voltaire/z&v8.jpg")
      },
      {
        original: require("../../assets/images/zadig&voltaire/z&v9.jpg"),
        thumbnail: require("../../assets/images/zadig&voltaire/z&v9.jpg")
      },
      {
        original: require("../../assets/images/zadig&voltaire/z&v10.jpg"),
        thumbnail: require("../../assets/images/zadig&voltaire/z&v10.jpg")
      }
    ];

    return (
      <MDBRow className="Real">
        <h1>Pose d'un volet roulant</h1>
        <ImageGallery
          items={images}
          showIndex={true}
          showPlayButton={false}
          showThumbnails={false}
          showFullscreenButton={false}
        />
      </MDBRow>
    )
  }
}

export default Real6;