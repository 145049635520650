import React, { Component } from 'react';

import { MDBRow } from 'mdbreact';
import ImageGallery from 'react-image-gallery';

import '../../../node_modules/react-image-gallery/styles/css/image-gallery.css';

class Real11 extends Component {

  render() {
    
    const images = [
      {
        original: require("../../assets/images/picard/picard1.jpg"),
        thumbnail: require("../../assets/images/picard/picard1.jpg")
      },
      {
        original: require("../../assets/images/picard/picard2.jpg"),
        thumbnail: require("../../assets/images/picard/picard2.jpg")
      },
      {
        original: require("../../assets/images/picard/picard3.jpg"),
        thumbnail: require("../../assets/images/picard/picard3.jpg")
      },
      {
        original: require("../../assets/images/picard/picard4.jpg"),
        thumbnail: require("../../assets/images/picard/picard4.jpg")
      }
    ];

    return (
      <MDBRow className="Real">
        <h1>Changement d'un axe de rideau métallique</h1>
        <ImageGallery
          items={images}
          showIndex={true}
          showPlayButton={false}
          showThumbnails={false}
          showFullscreenButton={false}
        />
      </MDBRow>
    )
  }
}

export default Real11;