import React, { Component } from 'react';

import { MDBRow } from 'mdbreact';
import ImageGallery from 'react-image-gallery';

import '../../../node_modules/react-image-gallery/styles/css/image-gallery.css';

class Real14 extends Component {

  render() {
    
    const images = [
      {
        original: require("../../assets/images/auchan/auchan1.jpg"),
        thumbnail: require("../../assets/images/auchan/auchan1.jpg")
      },
      {
        original: require("../../assets/images/auchan/auchan2.jpg"),
        thumbnail: require("../../assets/images/auchan/auchan2.jpg")
      },
      {
        original: require("../../assets/images/auchan/auchan3.jpg"),
        thumbnail: require("../../assets/images/auchan/auchan3.jpg")
      },
      {
        original: require("../../assets/images/auchan/auchan4.jpg"),
        thumbnail: require("../../assets/images/auchan/auchan4.jpg")
      },
      {
        original: require("../../assets/images/auchan/auchan5.jpg"),
        thumbnail: require("../../assets/images/auchan/auchan5.jpg")
      },
      {
        original: require("../../assets/images/auchan/auchan6.jpg"),
        thumbnail: require("../../assets/images/auchan/auchan6.jpg")
      },
      {
        original: require("../../assets/images/auchan/auchan7.jpg"),
        thumbnail: require("../../assets/images/auchan/auchan7.jpg")
      },
      {
        original: require("../../assets/images/auchan/auchan8.jpg"),
        thumbnail: require("../../assets/images/auchan/auchan8.jpg")
      },
      {
        original: require("../../assets/images/auchan/auchan9.jpg"),
        thumbnail: require("../../assets/images/auchan/auchan9.jpg")
      }
    ];

    return (
      <MDBRow className="Real">
        <h1>Pose d'un rideau métallique</h1>
        <ImageGallery
          items={images}
          showIndex={true}
          showPlayButton={false}
          showThumbnails={false}
          showFullscreenButton={false}
        />
      </MDBRow>
    )
  }
}

export default Real14;