import React, { Component } from 'react';
import { MDBRow, MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCol } from 'mdbreact';
import { NavLink } from 'react-router-dom';

const lock = require('../assets/images/locks/lock1.jpg');
const real = require('../assets/images/real.jpg');
const societe = require('../assets/images/marion-jean.jpg');
const contact = require('../assets/images/logo.jpg');

class Accueil extends Component {
  render() {
    return (
      <MDBRow className="Accueil">
        <h1>Accueil</h1>
        <MDBRow>
          <MDBCol xs="3" md="3" className="Col">
            <MDBCard className="Card">
              <MDBCardBody className="CardBody">
                <MDBCardTitle className="CardTitle">Tarifs</MDBCardTitle>
              </MDBCardBody>
              <MDBCardImage className="img-fluid CardImg" src={lock} waves />
              <MDBCardBody className="CardBody">
                <MDBBtn className="CardBtn">
                  <NavLink className="nav-link" to="/tarifs">
                    Nos tarifs
                  </NavLink>
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol xs="3" md="3" className="Col">
            <MDBCard className="Card">
              <MDBCardBody className="CardBody">
                <MDBCardTitle className="CardTitle">Réalisations</MDBCardTitle>
              </MDBCardBody>
              <MDBCardImage className="img-fluid CardImg" src={real} waves />
              <MDBCardBody className="CardBody">
                <MDBBtn className="CardBtn">
                  <NavLink className="nav-link" to="/realisations">
                    Nos réalisations
                  </NavLink>
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol xs="3" md="3" className="Col">
            <MDBCard className="Card">
              <MDBCardBody className="CardBody">
                <MDBCardTitle className="CardTitle">Société</MDBCardTitle>
              </MDBCardBody>
              <MDBCardImage className="img-fluid CardImg" src={societe} waves />
              <MDBCardBody className="CardBody">
                <MDBBtn className="CardBtn">
                  <NavLink className="nav-link" to="/societe">
                    Société
                  </NavLink>
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol xs="3" md="3" className="Col">
            <MDBCard className="Card">
              <MDBCardBody className="CardBody">
                <MDBCardTitle className="CardTitle">Contact</MDBCardTitle>
              </MDBCardBody>
              <MDBCardImage className="img-fluid CardImg" src={contact} waves />
              <MDBCardBody className="CardBody">
                <MDBBtn className="CardBtn">
                  <NavLink className="nav-link" to="/contact">
                    Contact
                  </NavLink>
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBRow>
    )
  }
}

export default Accueil;